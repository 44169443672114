/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router';

export const PureSEO = ({
  description,
  lang,
  location,
  meta,
  keywords,
  title,
  image,
  site,
  children,
}) => {
  let metaDescription =
    description ||
    site.siteMetadata.description

  // Clean up any HTML that might be present in the description.
  metaDescription = metaDescription.replace(/(<([^>]+)>)/gi, '');

  const metaDefaults = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:url`,
      content: location.href,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:site`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ]
    .concat(
      keywords.length > 0
        ? {
            name: `keywords`,
            content: keywords.join(`, `),
          }
        : []
    )
    .concat(
      image !== null
        ? [
            {
              name: `twitter:image`,
              content: `${site.siteMetadata.siteUrl}${image}`,
            },
            {
              name: `og:image`,
              content: `${site.siteMetadata.siteUrl}${image}`,
            },
          ]
        : []
    );

  // Merge the metaDefaults from above, with whatever is passed in via the meta
  // prop. And dedupe when there's an overlap in either the 'name', or
  // 'property' value. Prefer the one from the meta prop.
  metaDefaults.forEach((oldValue, oldIdx) => {
    meta.forEach(newValue => {
      if (
        (newValue.name && newValue.name === oldValue.name) ||
        (newValue.property && newValue.property === oldValue.property)
      ) {
        metaDefaults.splice(oldIdx, 1, newValue);
      }
    });
  });

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={metaDefaults}
    >
      {children}
    </Helmet>
  );
};

export const SEO = props => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  return (
    <Location>
      {locationProps => <PureSEO {...locationProps} {...props} site={site} />}
    </Location>
  );
};

PureSEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
  image: null,
};

PureSEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  location: PropTypes.object.isRequired,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  site: PropTypes.object.isRequired,
};

export default SEO;
