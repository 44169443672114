import React, { useEffect } from 'react';
import { Button, Container } from 'semantic-ui-react';

import './Banner.less';

const Banner = () => {
  useEffect(() => {
    // SDK is loaded in gatsby-ssr.js.
    const button = PayPal.Donation.Button({
      env:'production',
      hosted_button_id:'MVWSDW53L6364',
      image: {
        src:'https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif',
        alt:'Donate with PayPal button',
        title:'PayPal - The safer, easier way to pay online!',
      }
    });
    button.render('#donate-button');
  }, [])

  return (
    <Container className="donate-banner">
      <p>Help us continue to create high quality Node.js tutorials by supporting our work.</p>
      <div id="donate-button"></div>
    </Container>
  );
}

export default Banner;
