import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Button, Container, Grid, Icon, Image } from 'semantic-ui-react';
import './header.less';

import DrupalLoginButton from './drupal-oauth/DrupalLoginButton';
import LogoutLink from './drupal-oauth/LogoutLink';
import MyAccountLink from './drupal-oauth/MyAccountLink';

import HeyNodeLogo from '../assets/images/heynode-logo.svg';

import Banner from './Donate/Banner';

const LogoutButton = () => (
  <LogoutLink
    component={({ onClick }) => (
      <Button onClick={onClick} basic>
        Log out
      </Button>
    )}
  />
);

const Header = ({ siteTitle, userAuthenticated }) => (
  <Container>
    <Banner />
    <Grid className="header" stackable columns={2}>
      <Grid.Row>
        <Grid.Column className="header--logo">
          <Link to="/" title="Homepage" className="home">
            <Image src={HeyNodeLogo} alt={siteTitle} />
          </Link>
          <Link to="/about" title="About" className="about">
            About
          </Link>
          <Link to="/blog" title="Blog" className="blog">
            Blog
          </Link>
        </Grid.Column>
        <Grid.Column textAlign="right" className="header--buttons">
          {userAuthenticated ? (
            <>
              <MyAccountLink className="myaccount" title="My account" />
              <LogoutButton />
            </>
          ) : (
            <>
              <DrupalLoginButton
                title="Log in"
                classes="ui button large primary inverted signin"
              />
              <DrupalLoginButton
                title={
                  <>
                    <Icon name="right arrow" />
                    Sign up
                  </>
                }
                useRegistrationLink
                classes="ui icon primary left labeled button signup"
              />
            </>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
  userAuthenticated: PropTypes.bool,
};

Header.defaultProps = {
  siteTitle: ``,
  userAuthenticated: false,
};

export default Header;
