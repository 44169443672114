import React from 'react';
import { Link } from 'gatsby';
import { Container } from 'semantic-ui-react';

import './Footer.less';
import OsioLabsLogoSVG from '../../assets/images/osio-labs-logo.svg';

const Footer = () => (
  <footer className="footer">
    <Container>
      <div className="left">
        {' '}
        <a className="logo-link" href="https://osiolabs.com">
          <img className="logo" src={OsioLabsLogoSVG} alt="Osio Labs, Inc." />
        </a>
        <br />
        {new Date().getFullYear()} &copy; Osio Labs, Inc.
      </div>
      <div className="right">
        <Link to="/about">About</Link> &middot; <Link to="/blog">Blog</Link>{' '}
        &middot;{' '}
        <a href="https://osiolabs.com/terms-conditions">Terms of use</a>{' '}
        &middot; <a href="https://osiolabs.com/privacy">Privacy</a>
      </div>
    </Container>
  </footer>
);

export default Footer;
