/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Container } from 'semantic-ui-react';

import withDrupalOauthConsumer from './drupal-oauth/withDrupalOauthConsumer';
import DrupalOauth from './drupal-oauth/DrupalOauth';
import withDrupalOauthProvider from './drupal-oauth/withDrupalOauthProvider';
import ReceiveFromDrupal from './drupal-oauth/ReceiveFromDrupal';
import Header from './header';
import Footer from './Footer/Footer';

// Initialize a new DrupalOauth client which we can use to seed the context
// provider.
const drupalOauthClient = new DrupalOauth({
  drupal_root: process.env.GATSBY_DRUPAL_API_ROOT,
  client_id: process.env.GATSBY_DRUPAL_API_ID,
  client_secret: process.env.GATSBY_DRUPAL_API_SECRET,
});

const OauthHeader = withDrupalOauthConsumer(Header);

const Layout = ({ children, className, withContainer }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className={className}>
        <ReceiveFromDrupal />
        <OauthHeader siteTitle={data.site.siteMetadata.title} />
        {withContainer ? (
          <Container as="main">{children}</Container>
        ) : (
          <>{children}</>
        )}
        <Footer />
      </div>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  withContainer: PropTypes.bool,
};

Layout.defaultProps = {
  withContainer: true,
};

export default withDrupalOauthProvider(drupalOauthClient, Layout);
